import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";

import { displayAlert } from "../../../../../slices/feedbackSlice";
import { formatDate, round } from "../../../../../utils/helpers";
import TextField from "@mui/material/TextField";
import {
  updateOrgConfig,
  createOrgConfig,
} from "../../../../../slices/accountSlice";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InfoBox } from "../../../../Layout/InfoBox/InfoBox";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  summary: {
    padding: "0.75rem",
  },

  infoBox: {
    marginTop: "1.5rem",
  },
  accordion: {
    marginBottom: "1rem",
  },
  date: {
    margin: "auto 10px auto 0",
  },
}));

export default function OrganizationConfig({
  config,
  newConfig,
  userId,
  cancelNewConfig,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});

  const [expanded, setExpanded] = useState(!!newConfig);
  const [loading, setLoading] = useState(false);
  const [publicHolidays, setPublicHolidays] = useState(
    config.public_holidays || []
  );
  const [workedDays, setWorkedDays] = useState([]);
  const organization = useSelector((state) => state.account.organization);

  const { t, i18n } = useTranslation(["myAccount", "common"]);
  const locale = i18n.language;

  const timeUnits = [
    <MenuItem key="hour" value="hour">
      {t("rules.hour")}
    </MenuItem>,
    <MenuItem key="day" value="day" id="day">
      {t("rules.day")}
    </MenuItem>,
    <MenuItem key="week" value="week">
      {t("rules.week")}
    </MenuItem>,
    <MenuItem key="month" value="month">
      {t("rules.month")}
    </MenuItem>,
    <MenuItem key="year" value="year">
      {t("rules.year")}
    </MenuItem>,
  ];
  const timeUnitsPlural = [
    <MenuItem key="hour" value="hour">
      {t("rules.hours")}
    </MenuItem>,
    <MenuItem key="day" value="day" id="day">
      {t("rules.days")}
    </MenuItem>,
    <MenuItem key="week" value="week">
      {t("rules.weeks")}
    </MenuItem>,
    <MenuItem key="month" value="month">
      {t("rules.months")}
    </MenuItem>,
    <MenuItem key="year" value="year">
      {t("rules.years")}
    </MenuItem>,
  ];

  const bankMethod = [
    <MenuItem
      key="fiscal_year_yearly"
      value="fiscal_year_yearly"
      id="fiscal_year_yearly"
    >
      {t("rules.methods.fiscal_year_start")}
    </MenuItem>,
    <MenuItem
      key="contract_anniversary_yearly"
      value="contract_anniversary_yearly"
      id="contract_anniversary_yearly"
    >
      {t("rules.methods.contract_start")}
    </MenuItem>,
    <MenuItem key="monthly" value="monthly" id="monthly">
      {t("rules.methods.monthly")}
    </MenuItem>,
    <MenuItem key="progressive" value="progressive" id="progressive">
      {t("rules.methods.progressive")}
    </MenuItem>,
    <MenuItem key="date" value="date" id="date">
      {t("rules.methods.date")}
    </MenuItem>,
  ];
  const bankReset = [
    <MenuItem
      key="fiscal_year_yearly"
      value="fiscal_year_yearly"
      id="fiscal_year_yearly"
    >
      {t("rules.methods.fiscal_year_start")}
    </MenuItem>,
    <MenuItem
      key="contract_anniversary_yearly"
      value="contract_anniversary_yearly"
      id="contract_anniversary_yearly"
    >
      {t("rules.methods.contract_anniversary")}
    </MenuItem>,
    <MenuItem key="monthly" value="monthly" id="monthly">
      {t("rules.methods.monthly")}
    </MenuItem>,
    <MenuItem key="never" value="never" id="never">
      {t("rules.methods.never")}
    </MenuItem>,
    <MenuItem key="date" value="date" id="date">
      {t("rules.methods.date")}
    </MenuItem>,
  ];

  const daysOfWeek = [
    { id: 0, label: t("Monday", { ns: "common" }) },
    { id: 1, label: t("Tuesday", { ns: "common" }) },
    { id: 2, label: t("Wednesday", { ns: "common" }) },
    { id: 3, label: t("Thursday", { ns: "common" }) },
    { id: 4, label: t("Friday", { ns: "common" }) },
    { id: 5, label: t("Saturday", { ns: "common" }) },
    { id: 6, label: t("Sunday", { ns: "common" }) },
  ];

  const formatAutocompleteDaysList = (dayIds) => {
    return dayIds.map((id) =>
      daysOfWeek.find((dayOfWeek) => dayOfWeek.id === id)
    );
  };

  useEffect(() => {
    const orderedWorkedDays = [...config.worked_days];
    orderedWorkedDays.sort((a, b) => a - b);
    const formattedWorkedDays = formatAutocompleteDaysList(orderedWorkedDays);
    setWorkedDays(formattedWorkedDays);
  }, [config]);

  useEffect(() => {
    setFields({
      validFrom: {
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        name: "validFrom",
        label: t("From"),
        id: "validFrom",
        value: config.valid_from ? dayjs(config.valid_from) : dayjs(new Date()),
      },

      unit: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: true,
        name: "unit",
        label: t("rules.time_unit"),
        id: "unit",
        value: config.vacation_time_per_period.unit || "error",
        children: timeUnitsPlural,
      },
      period: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: true,
        name: "period",
        label: t("rules.time_period"),
        id: "period",
        value: config.vacation_time_per_period.period || "error",
        children: timeUnits,
      },
      vacationValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "vacationValue",
        label: t("rules.time_value"),
        name: "vacationValue",
        value: config.vacation_time_per_period?.time
          ? round(config.vacation_time_per_period.time)
          : 0,
        InputLabelProps: { shrink: true },
      },
      sickValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "sickValue",
        label: t("rules.time_value"),
        name: "sickValue",
        value: config.sick_time_per_period?.time
          ? round(config.sick_time_per_period.time)
          : 0,
        InputLabelProps: { shrink: true },
      },
      familyValue: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "familyValue",
        label: t("rules.time_value"),
        name: "familyValue",
        value: config.family_days_per_year
          ? round(config.family_days_per_year)
          : 0,
        InputLabelProps: { shrink: true },
      },
      hoursPerWeek: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "hoursPerWeek",
        label: t("rules.hours"),
        name: "hoursPerWeek",
        value: config.worked_hours_per_week || 0,
        InputProps: {
          inputProps: {
            max: 24 * 7,
            min: 0,
          },
        },
        InputLabelProps: { shrink: true },
      },
      maxCumulatedOvertimeHours: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "maxCumulatedOvertimeHours",
        label: t("rules.hours"),
        name: "maxCumulatedOvertimeHours",
        value: config.max_cumulated_overtime_hours,
        InputProps: {
          inputProps: {
            min: 0,
          },
        },
        InputLabelProps: { shrink: true },
      },
      maxCumulatedSickHours: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "maxCumulatedSickHours",
        label: t("rules.hours"),
        name: "maxCumulatedSickHours",
        value: config.max_cumulated_sick_hours,
        InputProps: {
          inputProps: {
            min: 0,
          },
        },
        InputLabelProps: { shrink: true },
      },
      overtimeExtraConfig: {
        id: "overtimeExtraConfig",
        name: "overtimeExtraConfig",
        value: config.overtime_xtra || {},
      },
      sickDaysMethod: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: false,
        id: "sickDaysMethod",
        label: t("rules.method"),
        name: "sickDaysMethod",
        value: config.sick_days_method,
        InputLabelProps: { shrink: true },
        children: bankMethod,
      },
      sickDaysReset: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: false,
        id: "sickDaysReset",
        label: t("rules.time_period"),
        name: "sickDaysReset",
        value: config.sick_days_reset,
        InputLabelProps: { shrink: true },
        children: bankReset,
      },
      sickDaysDate: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: false,
        id: "sickDaysDate",
        label: t("rules.time_period"),
        name: "sickDaysDate",
        value: config.sick_days_date,
        InputLabelProps: { shrink: true },
      },
      vacationDaysMethod: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: false,
        id: "vacationDaysMethod",
        label: t("rules.method"),
        name: "vacationDaysMethod",
        value: config.vacation_days_method,
        InputLabelProps: { shrink: true },
        children: bankMethod,
      },
      vacationDaysReset: {
        variant: "outlined",
        required: true,
        select: true,
        fullWidth: false,
        id: "vacationDaysReset",
        label: t("rules.time_period"),
        name: "vacationDaysReset",
        value: config.vacation_days_reset,
        InputLabelProps: { shrink: true },
        children: bankReset,
      },
      vacationDaysDate: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "vacationDaysDate",
        label: t("rules.date"),
        name: "vacationDaysDate",
        value: config.vacation_days_date,
        InputLabelProps: { shrink: true },
      },
      publicHolidays: {
        type: "date",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "publicHolidays",
        name: "publicHolidays",
        value: null,
      },
      compensatoryDaysPerYear: {
        type: "number",
        variant: "outlined",
        required: true,
        fullWidth: true,
        id: "compensatoryDaysPerYear",
        label: t("rules.hours"),
        name: "compensatoryDaysPerYear",
        value: config.compensatory_days_per_year,
        InputLabelProps: { shrink: true },
      },
    });
  }, [t, config]);

  const handleChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { id, value } = target;
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    });
  };

  const handleChangeDate = (value, id) => {
    const newFields = {
      ...fields,
      [id]: {
        ...fields[id],
        value,
      },
    };

    setFields(newFields);
  };

  const handleChangeOvertimeExtra = (event) => {
    const { target } = event;
    const { id, value } = target;
    const newOvertimeExtraConfigValue = {
      ...fields.overtimeExtraConfig.value,
      [id]: parseFloat(value),
    };

    const newOvertimeExtraConfig = {
      ...fields.overtimeExtraConfig,
    };

    newOvertimeExtraConfig.value = newOvertimeExtraConfigValue;

    const newFields = {
      ...fields,
      overtimeExtraConfig: newOvertimeExtraConfig,
    };
    setFields(newFields);
  };

  const handleSelect = (event) => {
    event.preventDefault();
    const { target } = event;
    const { name, value } = target;
    setFields({
      ...fields,
      [name]: {
        ...fields[name],
        value,
      },
    });
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (newConfig) {
      cancelNewConfig();
    }
  };

  const handleSuccess = () => {
    dispatch(displayAlert(t("rules_update_success"), "success"));
    setLoading(false);
    if (newConfig) {
      cancelNewConfig();
    }
  };

  const handleError = (err) => {
    dispatch(displayAlert(t("error"), "error"));
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    const organizationConfig = Object.values(fields).reduce((acc, elem) => {
      acc[elem.id] = elem.value;
      return acc;
    }, {});

    organizationConfig.id = config.id;
    organizationConfig.userId = userId;
    organizationConfig.workedDays = workedDays.map((workedDay) => workedDay.id);
    organizationConfig.daysPerWeek = workedDays.length;

    organizationConfig.validFrom =
      organizationConfig.validFrom.format("YYYY-MM-DD");

    const configWithPublicHolidays = {
      ...organizationConfig,
      publicHolidays,
    };

    newConfig
      ? dispatch(createOrgConfig(configWithPublicHolidays))
          .unwrap()
          .then(() => handleSuccess())
          .catch((err) => handleError(err))
      : dispatch(updateOrgConfig(configWithPublicHolidays))
          .unwrap()
          .then(() => handleSuccess())
          .catch((err) => handleError(err));
  };

  const handleDeletePublicHoliday = (date) => {
    const newPublicHolidays = [...(publicHolidays || [])];
    const index = newPublicHolidays.indexOf(date);
    if (index > -1) {
      newPublicHolidays.splice(index, 1); // 2nd parameter means remove one item only
    }
    setPublicHolidays(newPublicHolidays);
  };

  const handleAddPublicHoliday = (event) => {
    event.preventDefault();
    const { value } = fields.publicHolidays;
    if (value) {
      const newPublicHolidays = [...(publicHolidays || [])];
      newPublicHolidays.push(value.format("YYYY-MM-DD"));
      setPublicHolidays(newPublicHolidays);
    }
  };

  const handleChangeWorkedDays = (event, selectedWorkedDays) => {
    setWorkedDays(selectedWorkedDays);
  };

  const organizationRules = () => {
    return (
      <Grid container spacing={2}>
        {/* Vacations */}
        <Grid item xs={12}>
          <Typography component="h3" variant="h3">
            {t("rules.vacation")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField {...fields.vacationValue} onChange={handleChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField {...fields.unit} onChange={handleSelect} />
        </Grid>
        <Grid item xs={1} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            align="center"
            margin="auto"
          >
            {t("rules.by")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField {...fields.period} onChange={handleSelect} />
        </Grid>
        {/* Sickness */}
        <Grid item xs={12}>
          <Typography component="h3" variant="h3" marginTop={"1rem"}>
            {t("rules.sickness")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField {...fields.sickValue} onChange={handleChange} />
        </Grid>
        <Grid item xs={4} display="flex">
          <Typography
            component="p"
            variant="body1"
            align="left"
            margin="auto 0"
          >
            {t(`rules.plural.${fields.unit?.value}`)} {t("rules.by")}{" "}
            {t(`rules.singular.${fields.period?.value}`)}
          </Typography>
        </Grid>
        {/* Family days */}
        {organization?.timesheet_columns.has_family_hours && (
          <>
            <Grid item xs={12}>
              <Typography component="h3" variant="h3" marginTop={"1rem"}>
                {organization?.timesheet_columns.family_hours_custom_name}
              </Typography>
            </Grid>
            <Grid item xs={3} md={2}>
              <TextField {...fields.familyValue} onChange={handleChange} />
            </Grid>
            <Grid item xs={4} display="flex">
              <Typography
                component="p"
                variant="body1"
                align="left"
                margin="auto 0"
              >
                {t(`rules.days`)} {t("rules.by")} {t(`rules.year`)}
              </Typography>
            </Grid>
          </>
        )}
        {/* Banks methods */}
        <Grid item xs={12} marginTop={4}>
          <Typography component="h3" variant="h3">
            {t("rules.methods.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InfoBox level={"info"}>
            {t("rules.methods.info_1")}
            <p>
              {t("rules.methods.info_2")}

              <a
                href={
                  "https://feuilledetemps.ca/docs/tutorial-admin/methode-calcul-banques"
                }
                target="_blank"
                rel="noopener"
              >
                {t("rules.methods.info_link")}
              </a>
            </p>
          </InfoBox>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4">
            {t("rules.vacation")}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography
            component="body1"
            variant="p"
            align="left"
            margin="auto 0"
            width={"25%"}
          >
            {t("rules.methods.hours_gained")}
          </Typography>
          <TextField {...fields.vacationDaysMethod} onChange={handleSelect} />
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography
            component="body1"
            variant="p"
            align="left"
            margin="auto 0"
            width={"25%"}
          >
            {t("rules.methods.hours_reset")}
          </Typography>
          <TextField {...fields.vacationDaysReset} onChange={handleSelect} />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4">
            {t("rules.sickness")}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography
            component="body1"
            variant="p"
            align="left"
            margin="auto 0"
            width={"25%"}
          >
            {t("rules.methods.hours_gained")}
          </Typography>
          <TextField {...fields.sickDaysMethod} onChange={handleSelect} />
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography
            component="body1"
            variant="p"
            align="left"
            margin="auto 0"
            width={"25%"}
          >
            {t("rules.methods.hours_reset")}
          </Typography>
          <TextField {...fields.sickDaysReset} onChange={handleSelect} />
        </Grid>
        {/* Working time */}
        <Grid item xs={12} marginTop={4}>
          <Typography component="h3" variant="h3">
            {t("rules.working_time")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField {...fields.hoursPerWeek} onChange={handleChange} />
        </Grid>
        <Grid item xs={6} display="flex">
          <Typography
            component="p"
            variant="body1"
            align="left"
            margin="auto 0"
          >
            {t("rules.hours")} {/* {t("rules.in")} {workedDays.length} */}
            {/* {t("rules.days")}  */}
            {t("rules.by")} {t("rules.week")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={daysOfWeek}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            value={workedDays}
            onChange={handleChangeWorkedDays}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t("worked_days", { ns: "common" })}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} marginTop={4}>
          <Typography component="h3" variant="h3">
            {t("rules.max_cumulated")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            {...fields.maxCumulatedOvertimeHours}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={5} md={6} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            margin="auto"
            marginLeft={0}
          >
            {t("rules.max_cumulated_overtime_hours")}
          </Typography>
        </Grid>

        {/* Public holidays */}
        <Grid item xs={12} marginTop={4}>
          <Typography component="h3" variant="h3">
            {t("rules.public_holidays")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={3}>
          <DatePicker
            {...fields.publicHolidays}
            onChange={(value) => handleChangeDate(value, "publicHolidays")}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Button
            variant="outlined"
            component="label"
            color="darkPrimary"
            onClick={handleAddPublicHoliday}
            disabled={!(fields.publicHolidays && fields.publicHolidays.value)}
            sx={{ height: "100%" }}
          >
            {t("rules.add_public_holiday")}
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} marginleft={"0.05rem"}>
            {[...publicHolidays]
              .sort((a, b) => new Date(a) - new Date(b))
              .map((date) => (
                <Grid
                  item
                  xs={3}
                  key={date}
                  display={"flex"}
                  alignContent={"center"}
                >
                  <div className={classes.date}>{formatDate(date, locale)}</div>
                  <div>
                    <DeleteForeverIcon
                      color={"error"}
                      onClick={() => handleDeletePublicHoliday(date)}
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h3" variant="h3" marginTop={"1rem"}>
            {t("rules.overtime_extra")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InfoBox level="info">
            {t("rules.overtime_extra_info")}
            <br /> {t("rules.overtime_extra_exemple")}
            <br /> {t("rules.overtime_extra_info_2")}
          </InfoBox>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h5" variant="h5">
            {t("rules.overtime_extra_day")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            align="center"
            margin="auto"
          >
            {t("rules.hours_extra")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField
            value={parseFloat(
              fields.overtimeExtraConfig?.value.day_overtime_xtra_1 || 1
            )}
            type={"number"}
            id="day_overtime_xtra_1"
            onChange={handleChangeOvertimeExtra}
          />
        </Grid>
        <Grid item xs={2} md={1} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            align="center"
            margin="auto"
          >
            {t("rules.after")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField
            value={parseFloat(
              fields.overtimeExtraConfig?.value.day_hours_before_xtra_1 || 8
            )}
            type={"number"}
            id="day_hours_before_xtra_1"
            onChange={handleChangeOvertimeExtra}
          />
        </Grid>
        <Grid item xs={2} md={1} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            align="center"
            margin="auto"
          >
            {t("rules.hours")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h5" variant="h5">
            {t("rules.overtime_extra_week")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} justifyContent="center" display="flex">
          <Typography component="p" variant="body1" margin="auto">
            {t("rules.hours_extra")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField
            value={parseFloat(
              fields.overtimeExtraConfig?.value.week_overtime_xtra_1 || 1
            )}
            type={"number"}
            id="week_overtime_xtra_1"
            onChange={handleChangeOvertimeExtra}
          />
        </Grid>
        <Grid item xs={2} md={1} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            align="center"
            margin="auto"
          >
            {t("rules.after")}
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <TextField
            value={parseFloat(
              fields.overtimeExtraConfig?.value.week_hours_before_xtra_1 || 40
            )}
            type={"number"}
            id="week_hours_before_xtra_1"
            onChange={handleChangeOvertimeExtra}
          />
        </Grid>
        <Grid item xs={2} md={1} justifyContent="center" display="flex">
          <Typography
            component="p"
            variant="body1"
            align="center"
            margin="auto"
          >
            {t("rules.hours")}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const periodEdit = () => {
    return (
      <>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <DatePicker
                {...fields.validFrom}
                onChange={(value) => handleChangeDate(value, "validFrom")}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={toggleExpanded}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        {!expanded && (
          <>
            <Typography
              variant={"body1"}
              component={"span"}
              className={classes.summary}
            >
              {t("From")}
            </Typography>
            <Typography
              variant={"h5"}
              component={"span"}
              className={classes.summary}
            >
              {formatDate(config.valid_from, locale)}
            </Typography>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {expanded && periodEdit()}
          <Grid item sm={12}>
            {organizationRules()}
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions className={classes.actions}>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          onClick={toggleExpanded}
          sx={{ height: "100%" }}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          component="label"
          color="primary"
          onClick={handleSave}
          sx={{ height: "100%" }}
        >
          {t("save")}
        </LoadingButton>
      </AccordionActions>
    </Accordion>
  );
}
