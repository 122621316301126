import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Layout from "./components/Layout/Layout";
import "./App.css";
import routes from "./config/routes";
import theme from "./theme";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackGlobal from "./components/Screens/ErrorFallbackGlobal/ErrorFallbackGlobal";
import { getOrganization, getSelf } from "./slices/accountSlice";
import { setLoading } from "./slices/feedbackSlice";
import { getFiscalYears } from "./slices/timesheetsSlice";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import locale from "dayjs/locale/fr";
import { frFR } from "@mui/x-date-pickers/locales";

function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state) => state.account.isAdmin);
  const isTeamAdmin = useSelector((state) => state.account.isTeamAdmin);
  const organizationOptions = useSelector(
    (state) => state.account.organizationOptions
  );
  const organizationPlanType = useSelector(
    (state) => state.account.organizationPlanType
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && isAdmin) {
      dispatch(setLoading(true));
      dispatch(getOrganization())
        .unwrap()
        .then(() =>
          dispatch(getFiscalYears())
            .unwrap()
            .then(() => dispatch(setLoading(false)))
            .catch(() => dispatch(setLoading(false)))
        );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getSelf()).unwrap();
      dispatch(getOrganization());
    }
  }, [dispatch]);

  // eslint-disable-next-line react/display-name
  const withLayout = (Component, minimalLayout) => () =>
    (
      <Layout minimal={minimalLayout}>
        <Component />
      </Layout>
    );

  const defineRoute = (route) => {
    const Component = withLayout(route.component, route.minimalLayout);
    return <Route path={route.path} element={<Component />} key={route.path} />;
  };

  const publicRoutes = routes.public.map((route) => defineRoute(route));

  const loginRoute = defineRoute(routes.authentication.login);
  const signupRoute = defineRoute(routes.authentication.signup);
  const userSignupRoute = defineRoute(routes.authentication.userSignup);

  const protectedRoutes = routes.protected.filter((route) =>
    isAdmin
      ? true
      : isTeamAdmin
      ? !route.admin
      : !route.admin && !route.teamAdmin
  );

  const protectedRoutesFilteredByPlans = protectedRoutes.filter((route) =>
    route.planTypes ? route.planTypes.includes(organizationPlanType) : true
  );

  const protectedRoutesFilteredByOptions = protectedRoutesFilteredByPlans
    .filter((route) =>
      route.optionName ? organizationOptions.includes(route.optionName) : true
    )
    .map((route) => defineRoute(route));

  const defaultRedirect = isLoggedIn
    ? isAdmin
      ? routes.default.path.admin
      : routes.default.path.user
    : routes.authentication.login.path;

  // console.log(
  //   "isLoggedIn",
  //   isLoggedIn,
  //   "isAdmin",
  //   isAdmin,
  //   "isTeamAdmin",
  //   isTeamAdmin,
  //   "defaultRedirect",
  //   defaultRedirect
  // );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallbackGlobal}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fr-CA"
          >
            <Routes>
              ...
              {publicRoutes}
              ...
              {isLoggedIn ? protectedRoutesFilteredByOptions : []}
              {loginRoute}
              {signupRoute}
              {userSignupRoute}
              <Route path="*" element={<Navigate to={defaultRedirect} />} />
            </Routes>
          </LocalizationProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
