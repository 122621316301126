import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { formatDate, replaceObjectWithKey } from "../../../../utils/helpers";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { getActivityTypes } from "../../../../slices/projectsSlice";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    justifyContent: "space-between",
  },
  label: {
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
  },
  accordion: {
    marginBottom: "1rem",
    border: "none",
    width: "100%",
  },
  textField: {
    textAlign: "center",
  },
}));

export default function ProjectsModal({
  display,
  handleClose,
  projects,
  day,
  handleSaveProjectHours,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [projectHours, setProjectHours] = useState(day.project_hours);
  const [totalIsValid, setTotalIsValid] = useState(true);
  const [total, setTotal] = useState(0);
  const organization = useSelector((state) => state.account.organization);
  const activityTypes = useSelector((state) => state.projects.activityTypes);

  const { t } = useTranslation("timesheets");

  useEffect(() => {
    if (organization.options?.projects_details) {
      dispatch(getActivityTypes());
    }
  }, [organization]);

  useEffect(() => validateTotal(projectHours));
  const handleChangeProjectHours = (project, event) => {
    let value = event.target.value;
    value = value.replace(",", ".");
    if (value.startsWith("0") && !value.startsWith("0.")) {
      value = value.substring(1);
    }
    const updatedProjectHours = {
      project_id: project.id,
      hours: value || 0,
    };
    let newProjectHours;
    if (projectHours.find((e) => e.project_id === project.id)) {
      newProjectHours = replaceObjectWithKey(
        projectHours,
        updatedProjectHours,
        "project_id"
      );
    } else {
      newProjectHours = [...projectHours, updatedProjectHours];
    }
    setProjectHours(newProjectHours);
    validateTotal(newProjectHours);
    // }
  };

  const handleChangeComment = (project, event) => {
    let { value } = event.target;
    const currentProjectHours = projectHours.find(
      (e) => e.project_id === project.id
    );

    let updatedProjectHours;
    if (currentProjectHours) {
      updatedProjectHours = {
        ...currentProjectHours,
        note: value,
      };
    } else {
      updatedProjectHours = {
        project_id: project.id,
        hours: 0,
        note: value,
      };
    }

    let newProjectHours;
    if (projectHours.find((e) => e.project_id === project.id)) {
      newProjectHours = replaceObjectWithKey(
        projectHours,
        updatedProjectHours,
        "project_id"
      );
    } else {
      newProjectHours = [...projectHours, updatedProjectHours];
    }
    setProjectHours(newProjectHours);
    validateTotal(newProjectHours);
  };

  const projectHasActivities = (project) => {
    const found = activityTypes.find((type) => type.project === project.id);
    return !!found && !!organization.options.projects_details;
  };
  const handleChangeDetails = (project, type, event) => {
    let { value, id } = event.target;
    value = value.replace(",", ".");
    if (value.startsWith("0") && !value.startsWith("0.")) {
      value = value.substring(1);
    }
    const currentProjectHours = projectHours.find(
      (e) => e.project_id === project.id
    );

    const updatedActivity = {
      ...currentProjectHours.project_activities.find(
        (activity) => activity.type.id === parseInt(id)
      ),
    };

    updatedActivity.minutes = value;
    const updatedProjectActivities = replaceObjectWithKey(
      [...currentProjectHours.project_activities],
      updatedActivity,
      "id"
    );
    const updatedProjectHours = {
      ...currentProjectHours,
      project_activities: updatedProjectActivities,
    };
    let newProjectHours;
    if (projectHours.find((e) => e.project_id === project.id)) {
      newProjectHours = replaceObjectWithKey(
        projectHours,
        updatedProjectHours,
        "project_id"
      );
    } else {
      newProjectHours = [...projectHours, updatedProjectHours];
    }
    setProjectHours(newProjectHours);
    validateTotal(newProjectHours);
  };

  const handleSave = () => {
    handleSaveProjectHours(projectHours, day);
    handleClose();
  };

  const validateTotal = (newProjectHours) => {
    const totalHours = newProjectHours.reduce((acc, project) => {
      return acc + parseFloat(project.hours);
    }, 0);
    if (totalHours <= parseFloat(day.worked_hours)) {
      setTotalIsValid(true);
    } else setTotalIsValid(false);

    setTotal(totalHours);
  };

  return (
    <Dialog open={display} onClose={handleClose} fullWidth>
      <DialogTitle variant="h3">
        {t("projects_modal.title")} {formatDate(day.date)}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"left"}
            marginTop={2}
            marginBottom={2}
          >
            <Typography
              variant="h4"
              component="h4"
              className={classes.label}
              textAlign={"right"}
            >
              {t("projects_modal.total")}
              {" : "} {total} {"h"} {t("on")} {parseFloat(day.worked_hours)}
            </Typography>
          </Grid>
          {projects.map((project) => {
            const shouldExpand = projectHasActivities(project);
            return (
              <Grid key={project.id} item xs={12} display={"flex"}>
                <Accordion id={project.id} className={classes.accordion}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Grid item xs={12} sm={10} display={"flex"}>
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.label}
                      >
                        {project.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1} marginRight={2}>
                      <TextField
                        type="text"
                        value={
                          projectHours.find(
                            (hours) => hours.project_id === project.id
                          )
                            ? projectHours.find(
                                (hours) => hours.project_id === project.id
                              )?.hours
                            : 0
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 24,
                            style: { textAlign: "center" },
                          },
                        }}
                        onChange={(event) =>
                          handleChangeProjectHours(project, event)
                        }
                        onClick={(event) => event.stopPropagation()}
                        variant={"standard"}
                        fullWidth={false}
                      />
                    </Grid>
                    <Grid item xs={1} display={"flex"}>
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.label}
                      >
                        h
                      </Typography>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Divider />
                    <Grid container spacing={2} marginTop={2}>
                      {shouldExpand && (
                        <>
                          <Grid item sm={12} marginBottom={2}>
                            <Typography variant={"h4"}>
                              {t("projects_modal.details_title")}
                            </Typography>
                          </Grid>
                          {activityTypes.map((type) => {
                            if (type.project === project.id) {
                              return (
                                <React.Fragment key={type.id}>
                                  <Grid item xs={12} sm={9} key={type.id}>
                                    <Typography
                                      variant="h5"
                                      component="h5"
                                      className={classes.label}
                                    >
                                      {type.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={1} marginRight={2}>
                                    <TextField
                                      type="text"
                                      id={type.id}
                                      value={
                                        projectHours.find(
                                          (hours) =>
                                            hours.project_id === project.id
                                        )
                                          ? projectHours
                                              .find(
                                                (hours) =>
                                                  hours.project_id ===
                                                  project.id
                                              )
                                              ?.project_activities?.find(
                                                (activity) =>
                                                  activity.type.id === type.id
                                              )?.minutes
                                          : 0
                                      }
                                      InputProps={{
                                        inputProps: {
                                          min: 0,
                                          max: 24,
                                          style: { textAlign: "center" },
                                        },
                                      }}
                                      onChange={(event) =>
                                        handleChangeDetails(
                                          project,
                                          type,
                                          event
                                        )
                                      }
                                      variant={"standard"}
                                      fullWidth={false}
                                    />
                                  </Grid>
                                  <Grid item xs={1} display={"flex"}>
                                    <Typography
                                      variant="h4"
                                      component="h4"
                                      className={classes.label}
                                    >
                                      min
                                    </Typography>
                                  </Grid>
                                </React.Fragment>
                              );
                            }
                          })}
                        </>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          id={"note"}
                          label={t("note")}
                          value={
                            projectHours.find(
                              (hours) => hours.project_id === project.id
                            )?.note || ""
                          }
                          onChange={(event) => {
                            handleChangeComment(project, event);
                          }}
                          variant={"outlined"}
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
          {!totalIsValid && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="strong"
                color="error"
                textAlign="left"
              >
                {t("projects_modal.total_invalid")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          onClick={handleClose}
        >
          {t("projects_modal.cancel_button")}
        </Button>
        <Button
          variant="contained"
          component="label"
          color="primary"
          disabled={!totalIsValid}
          onClick={handleSave}
        >
          {t("projects_modal.confirm_button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
